<script lang="ts">
  // Import styles
  import "makeup-style/where-default.css";
  import "makeup-style/where-develop.css";
  import "$lib/assets/ishavskraft-font-faces.css";
  import "$lib/assets/ishavskraft-colors.css";
  import "$lib/assets/ishavskraft.css";
  import "$lib/assets/ishavskraft-interaction.css";
  import "$lib/assets/ishavskraft-icons.css";
  // Import scripts and components
  import { page } from "$app/state";
  import { browser } from "$app/environment";
  import { syncSessionValuesFromSearchParams } from "$lib/sessionValues";
  import { afterNavigate } from "$app/navigation";
  import Header from "$lib/Header.svelte";
  import Footer from "$lib/Footer.svelte";
  import Tracking from "$lib/Tracking.svelte";
  import AnnouncementBanner from "./AnnouncementBanner.svelte";
  import { setLangFromPath as setLang } from "$lib/langStore";

  interface Props {
    data: {
      ENVIRONMENT: string;
      fallbackMeta?: {
        image?: string;
        imageAlt?: string;
        imageWidth?: number;
        imageHeight?: number;
      };
    };
    children?: import("svelte").Snippet;
  }

  const topId = "start";
  let { data, children }: Props = $props();
  let isFront = $derived(page.url.pathname === "/");

  if (browser) {
    syncSessionValuesFromSearchParams(page.url.searchParams, { init: true });
  }

  // TODO: use page.data.languages to implement global language picker
  // $inspect("data.languages", page.data.languages);

  $effect(() => {
    const pathList = page.url.pathname.split("/");
    setLang(pathList[1]);
  });

  afterNavigate(() => {
    syncSessionValuesFromSearchParams(page.url.searchParams);
  });
</script>

<svelte:head>
  {#if !!data?.ENVIRONMENT?.match(/^preview?$/i)}
    <meta name="robots" content="noindex" />
  {:else}
    <script
      defer
      data-domain="ishavskraft.no"
      src="https://plausible.io/js/script.tagged-events.js"
    ></script>
    <meta name="google-site-verification" content="7ZRJwzTy7xj32zhaw23ls9JeAs28tSiChT8ZCYIoFTM" />
  {/if}
</svelte:head>

<!-- #ids for "back to top" and "skip link" -->
<div class="site-container relative z-1" id={topId}>
  <Header class="mb-1" />
  <main id="hovedinnhold" class="pb-4">
    <AnnouncementBanner />
    <Tracking class="text-sm tablet:text-base leading-tight" banner={true}>
      <a href="/om/personvern/cookies" class="no-underline inline-grid items-end"
        ><span
          ><strong>Dette nettstedet har cookies.</strong>
          <strong>Nødvendige</strong>
          for bestilling og eventuell rabatt. <strong>Markedsføring</strong> er valgfrie
          tredjeparter.
          <em class="underline">Om våre cookies</em></span
        ></a
      >
    </Tracking>
    {@render children?.()}
  </main>

  <Footer backToTopHrefId={topId} />

  <div
    class:hidden={!isFront}
    class="ishavskraft-graphic-container absolute inset-0 z--1 overflow-hidden grid place-items-center"
    aria-hidden="true"
  >
    <div class="graphic absolute" aria-hidden="true"></div>
  </div>
</div>

<style lang="postcss">
  /* Offset scroll for key navigation focus */
  :global(html) {
    scroll-padding-block: 10rem;
  }

  .ishavskraft-graphic-container {
    .graphic {
      --_s: min(125vw, 1000px);
      background: var(--triangle) no-repeat center / contain;
      rotate: 255deg;
      top: calc(200px - 0.5 * var(--_s) - 10vw);
      width: var(--_s);
      height: var(--_s);
      /* flip */
      transform: scaleX(-1);
    }
  }
</style>
