<script lang="ts">
  import { fetchAnnouncement, type Announcement } from "$lib/utils/fetchAnnouncement";
  import { PortableText } from "@portabletext/svelte";

  const shouldShow = (announcement: Announcement) => {
    if (announcement.active) {
      if (announcement.from) {
        if (new Date() < new Date(announcement.from)) {
          return false;
        }
      }
      if (announcement.to) {
        if (new Date() > new Date(announcement.to)) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  let { result = fetchAnnouncement() } = $props();
</script>

{#await result then result}
  {#if result?.data && shouldShow(result.data)}
    <div class="wrapper mb-4">
      <div class="bg-$warning-400 px-edge-x">
        {#each result.data.announcement as a}
          <PortableText value={a} />
        {/each}
      </div>
    </div>
  {/if}
{/await}

<style lang="postcss">
  .wrapper {
    animation: grow-down var(--easing) var(--duration, 0.5s) forwards;
    display: grid;

    > div {
      overflow: hidden;
    }
  }

  @keyframes grow-down {
    from {
      grid-template-rows: 0fr;
    }
    to {
      grid-template-rows: 1fr;
    }
  }
</style>
