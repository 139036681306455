import type { PortableTextBlock } from "@portabletext/types";

export type Announcement = {
  _type: string;
  announcement: PortableTextBlock[];
  active: boolean;
  from?: string;
  to?: string;
};

export type AnnouncementResponse = undefined | {
  data: Announcement | undefined;
};

export const fetchAnnouncement = async (sveltekitServerFetch?: typeof fetch): Promise<AnnouncementResponse> => {
  const apiUrl = `/api/privat/varsel`;
  const res = sveltekitServerFetch ? await sveltekitServerFetch(apiUrl) : await fetch(apiUrl);
  const data = await res.json();

  // Return articles with not future publishedAt
  if (res.ok) {
    return data;
  }

  return;
};
